/* eslint-disable react-hooks/exhaustive-deps */
import {
  Row,
  Col,
  Typography,
  Collapse,
  Form,
  Input,
  Button,
  Switch,
  Checkbox,
  Tooltip,
  Modal,
  Divider,
  Carousel,
  message,
  Skeleton,
} from "antd";
import {
  LeftCircleOutlined,
  RightCircleOutlined,
  MinusCircleOutlined,
  EyeTwoTone,
  EyeInvisibleOutlined,
} from "@ant-design/icons";
import React, { useState, useEffect, useRef } from "react";
import { API, graphqlOperation } from "aws-amplify";

import IntegartionIcons from "../../assets/Images/Integrationicon.svg";
import Shopify from "../../assets/Images/Shopify.svg";
import TriTech from "../../assets/Images/TriTech.svg";
import backArrow from "../../assets/Images/backArrow.svg";
import ActivateLogo from "../../assets/Images/ActivateLogo.svg";
import blustreamApp from "../../assets/Images/blustreamApp.png";
import triggerApp from "../../assets/Images/triggerApp.png";
import chooseAccount from "../../assets/Images/chooseAccount.png";
import apiKey from "../../assets/Images/apiKey.png";
import blustreamKey from "../../assets/Images/blustreamKey.png";
import selectFields from "../../assets/Images/selectFields.png";
import publish from "../../assets/Images/publish.png";
import pen from "../../assets/Images/pen.svg";
import glowingBulbIcon from "./../../assets/Images/glowingBulbIcon.svg";
import verifiedIcon from "../../assets/Images/verifiedIcon.svg";
import notVerifiedIcon from "../../assets/Images/notVerifiedIcon.svg";
import protectedFileIcon from "../../assets/Images/protectedFileIcon.svg";
import infoIcon from "../../assets/Images/infoIcon.svg";
import disconnectIcon from "../../assets/Images/disconnectIcon.svg";
import shopifyNativeConcent1 from "../../assets/Images/shopifyNativeConcent1.svg";
import shopifyNativeConcent2 from "../../assets/Images/shopifyNativeConcent2.svg";
import shopifyNativeConcent3 from "../../assets/Images/shopifyNativeConcent3.svg";
import shopifyNativeConcent4 from "../../assets/Images/shopifyNativeConcent4.svg";
import shopifyNativeConcent5 from "../../assets/Images/shopifyNativeConcent5.svg";
import shopifyNativeConcent6 from "../../assets/Images/shopifyNativeConcent6.svg";
import shopifyNativeConcent7 from "../../assets/Images/shopifyNativeConcent7.svg";
import shopifyNativeConcent8 from "../../assets/Images/shopifyNativeConcent8.svg";
import shopifyConcentPage from "../../assets/Images/shopifyConcentPage.svg";
import shopifyExtension1 from "../../assets/Images/shopifyExtension1.svg";
import shopifyExtension2 from "../../assets/Images/shopifyExtension2.svg";
import shopifyExtension3 from "../../assets/Images/shopifyExtension3.svg";
import shopifyExtension4 from "../../assets/Images/shopifyExtension4.svg";
import shopifyExtension5 from "../../assets/Images/shopifyExtension5.svg";
import shopifyExtension6 from "../../assets/Images/shopifyExtension6.svg";
import shopifyExtension7 from "../../assets/Images/shopifyExtension7.svg";
import disconnectShopify1 from "../../assets/Images/disconnectShopify1.svg";
import disconnectShopify2 from "../../assets/Images/disconnectShopify2.svg";
import disconnectShopify3 from "../../assets/Images/disconnectShopify3.svg";
import copyIcon from "../../assets/Images/copyIcon.svg";
import whatsapp from "../../assets/Images/whatsapp.svg";
import openBook from "../../assets/Images/openBook.svg";
import whatsappSlide1 from "../../assets/Images/whatsappSlide1.svg";
import whatsappSlide2 from "../../assets/Images/whatsappSlide2.svg";
import whatsappSlide3 from "../../assets/Images/whatsappSlide3.svg";
import whatsappSlide4 from "../../assets/Images/whatsappSlide4.svg";
import whatsappSlide5 from "../../assets/Images/whatsappSlide5.svg";
import whatsappSlide6 from "../../assets/Images/whatsappSlide6.svg";
import whatsappSlide7 from "../../assets/Images/whatsappSlide7.svg";
import whatsappSlide8 from "../../assets/Images/whatsappSlide8.svg";
import whatsappSlide9 from "../../assets/Images/whatsappSlide9.svg";

import extension from "../../assets/Images/extension.gif";

import sdk from "../../sdk/Accounts";
import { useDispatch, useSelector } from "react-redux";
import { deleteShopifyProfile, updateCustomerAccount } from "graphql/mutations";

import "../Integration/integartion.css";
import {
  setAccountDetails,
  setShopifyProfileId,
} from "store/actions/loginAction";
import { listShopifyProductMappings } from "./../../graphql/queries";
import { deleteShopifyProductMapping } from "./../../graphql/mutations";
import { onUpdateTriTechProfile } from "graphql/subscriptions";
import {
  addWhatsappConfiguration,
  getCustomerId,
  getWhatsappConfiguration,
} from "apiService/RESTApi";

const { Panel } = Collapse;

export default function Integartion() {
  const { roAdmin, accountDetails, groups, admin_groups_self } = useSelector(
    (state) => state.loginReducer
  );
  const { Paragraph } = Typography;
  const [whatsappForm] = Form.useForm();
  const isScoped = admin_groups_self && !admin_groups_self?.includes(groups[0]);
  const [tritechEventConfigureForm] = Form.useForm();

  const triTechEventsArray = [
    {
      Action: "NEW_CONTRACT",
      Description: "New Contract",
      Transaction_Status: [
        "C Budget Monthly Rental",
        "Best Value Monthly",
        "Auto Monthly Rental",
        "BME Premier Rental",
      ],
    },
    {
      Action: "EXCHANGED_CONTRACT",
      Description: "Contract Exchange",
      Transaction_Status: [
        "C Budget Monthly Rental",
        "Best Value Monthly",
        "Auto Monthly Rental",
        "BME Premier Rental",
      ],
    },
    {
      Action: "RETURN_CONTRACT",
      Description: "Contract Return",
      Transaction_Status: [
        "C Budget Monthly Rental",
        "Best Value Monthly",
        "Auto Monthly Rental",
        "BME Premier Rental",
      ],
    },
    {
      Action: "AIM_BLUSTREAM_HANDSHAKE",
      Description: "AIM Blustream Handshake",
    },
  ];

  const { TextArea } = Input;
  const slider1 = useRef(null);
  const slider2 = useRef(null);
  const slider3 = useRef(null);
  const slider4 = useRef(null);

  const [selectedPlatform, setSelectedPlatform] = useState("home");
  const [warningModalVisible, setWarningModalVisible] = useState(false);
  const [showShopifyConcentPage, setShowShopifyConcentPage] = useState(false);
  const [concentTextValue, setConcentTextValue] = useState("");
  const [concentTitleValue, setConcentTitleValue] = useState("");
  const [isShopifyProfileConnected, setIsShopifyProfileConnected] =
    useState(false);
  const [shopifyStoreName, setShopifyStoreName] = useState("");
  const [shopifyProfileIdValue, setShopifyProfileIdValue] = useState("");
  const [shopifyStoreSmsMarketingEnabledStatus, setShopifyStoreSmsMarketingEnabledStatus] = useState("LOADING");
  const [concentSaveLoader, setconcentSaveLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showDisconnectShopifyModal, setShowDisconnectShopifyModal] =
    useState(false);
  const [disonnectLoading, setDisonnectLoading] = useState(false);
  const [shopifyDomain, setShopifyDomain] = useState("");
  const [saveTriTechLoading, setSaveTriTechLoading] = useState(false);
  const [showShopifyNativeConcentModal, setShowShopifyNativeConcentModal] =
    useState(false);
  const [currentNativeSlideNumber, setCurrentNativeSlideNumber] = useState(0);
  const [
    showShopifyActivateExtensionModal,
    setShowShopifyActivateExtensionModal,
  ] = useState(false);
  const [showConcentTextModal, setShowConcentTextModal] = useState(false);
  const [endpoint, setEndpoint] = useState("");

  //tritech states
  const [tritechSecretKey, setTritechSecretKey] = useState("");
  const [isTritechConnected, setIsTritechConnected] = useState(false);
  const [triTechProfileId, setTriTechProfileId] = useState(null);
  const [triTechEvents, setTriTechEvents] = useState(null);
  const [showSecretKey, setShowSecretKey] = useState(false);
  const [updateTriTechLoading, setUpdateTriTechLoading] = useState(false);
  const [keyUpdateWarningModal, setKeyUpdateWarningModal] = useState(false);
  const [showEventConfigurationPage, setShowEventConfigurationPage] =
    useState(false);
  const [selectedEvent, setselectedEvent] = useState(null);
  const [transactionStatusArray, setTransactionStatusArray] = useState(null);
  const [showTriTechEventConfigureModal, setShowTriTechEventConfigureModal] =
    useState(false);
  const [updateTriTechEventLoader, setUpdateTriTechEventLoader] =
    useState(false);
  const [customerId, setCustomerId] = useState(null);
  const [whatsappSlider, setWhatsappSlider] = useState(0);
  const [whatsappConfiguration, setWhatsappConfiguration] = useState(null);
  const [isWhatsappConfigurationChanged, setIsWhatsappConfigurationChanged] =
    useState(false);
  const [whatsappConfigurationLoader, setWhatsappConfigurationLoader] =
    useState(false);
  const [whatsappWebhookVerifyLoader, setWhatsappWebhookVerifyLoader] =
    useState(false);
  const dispatch = useDispatch();

  const getConnectedTriTechProfile = async () => {
    await sdk
      .getTriTech(accountDetails?.id)
      .then((res) => {
        const tritechProfile = res?.data?.TriTechProfileByAccountId?.items?.[0];
        if (tritechProfile) {
          setIsTritechConnected(tritechProfile?.enabled);
          setTritechSecretKey(tritechProfile?.secret_key);
          setTriTechProfileId(tritechProfile?.id);
          setTriTechEvents(
            tritechProfile?.tritech_contract_action_mapper?.items
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    const updateTriTechProfileSubscription = API.graphql(
      graphqlOperation(onUpdateTriTechProfile)
    ).subscribe({
      next: () => {
        getConnectedTriTechProfile();
      },
      error: (error) => console.warn(error),
    });
    const getInitialValues = async () => {
      setLoading(true);
      const response = await getCustomerId(groups[0]);
      setCustomerId(response?.response?.data?.[0]?.customerId);
      getConnectedTriTechProfile();
      getConnectedShopifyProfile();
      checkAndUpdateStoreSmsMarketingEnabled();
      getEndpoint();
      getWhatsappConfiguration(response?.response?.data?.[0]?.customerId).then(
        (res) => {
          if (res?.response?.data && res?.response?.data?.length) {
            const data = res?.response?.data[0];
            setWhatsappConfiguration(data);
            whatsappForm?.setFieldsValue({
              authToken: data?.authToken,
              whatsappBusinessAccountId: data?.whatsappBusinessAccountId,
              phoneNumberId: data?.whatsappPhoneNumberId,
              originationPhoneNumber:
                data?.customerOriginationPhoneNumberDto?.phoneNumber,
            });
          }
        }
      );
    };
    getInitialValues();

    return () => {
      updateTriTechProfileSubscription?.unsubscribe();
    };
  }, []);

  const SampleNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block" }}
        onClick={onClick}
      >
        <RightCircleOutlined style={{ color: "black", fontSize: 25 }} />
      </div>
    );
  };

  const SamplePrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
        }}
        onClick={onClick}
      >
        <LeftCircleOutlined style={{ color: "black", fontSize: 25 }} />
      </div>
    );
  };

  const settings = {
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  const get_shopify_profile_details = async () => {
    try {
      const apiName = "blustreamREST";
      const profilePath =
        "/shopify-intergration/get-shopify-profile-attach-with-company";
      const profileBody = {
        companyId: accountDetails?.id,
      };
      const profileResponse = await API.post(apiName, profilePath, {
        body: profileBody,
      });
      if (profileResponse?.statusCode === 200) {
        setConcentTextValue(
          JSON.parse(profileResponse.body)?.shopifyprofiles[0]?.concent
        );
        setConcentTitleValue(
          JSON.parse(profileResponse.body)?.shopifyprofiles[0]?.concentTitle
        );
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const isStoreSmsMarketingEnabled = async () => {
    // LOADING, ENABLED, DISABLED, ERROR
    let isEnabled = "ERROR";
    try {
      const apiName = "blustreamREST";
      const requestPath = "/shopify-intergration/is-store-sms-marketing-enabled";
      const requestBody = {
        accountId: accountDetails?.id,
      };

      const storeSmsMarketingEnabledResponse = await API.post(apiName, requestPath, {
        body: requestBody,
      });
      console.log("Store Marketing Enabled Status: ", storeSmsMarketingEnabledResponse);

      if (storeSmsMarketingEnabledResponse?.statusCode === 200) {
        if(JSON.parse(storeSmsMarketingEnabledResponse.body)?.store_sms_marketing_enabled === true) {
          isEnabled = "ENABLED";
        } else {
          isEnabled = "DISABLED";
        }
      }
    } catch (error) {
      console.log("Could not get Store Marketing Enabled Status: ", error);
    }
    return isEnabled;
  };

  const checkAndUpdateStoreSmsMarketingEnabled = async () => {
    setShopifyStoreSmsMarketingEnabledStatus("LOADING");
    setShopifyStoreSmsMarketingEnabledStatus(await isStoreSmsMarketingEnabled());
  };

  const getConnectedShopifyProfile = async () => {
    setLoading(true);
    try {
      const apiName = "blustreamREST";
      const profilePath =
        "/shopify-intergration/get-shopify-profile-attach-with-company";
      const profileBody = {
        companyId: accountDetails?.id,
      };
      const profileResponse = await API.post(apiName, profilePath, {
        body: profileBody,
      });
      if (profileResponse?.statusCode === 200) {
        let dynamoShopifyProfile = JSON.parse(profileResponse.body)?.shopifyprofiles[0]
        setConcentTextValue(dynamoShopifyProfile?.concent);
        setConcentTitleValue(dynamoShopifyProfile?.concentTitle);
        setIsShopifyProfileConnected(true);
        setShopifyStoreName(dynamoShopifyProfile?.store_name.split(".")[0]);
        setShopifyDomain(dynamoShopifyProfile?.shopifyStoreDomain.split(".")[0]);
        setShopifyProfileIdValue(dynamoShopifyProfile?.id);
        setShowShopifyConcentPage(true);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchAccountDetails = async () => {
    const accountID = groups.length > 0 ? groups[0] : null;
    setTimeout(async () => {
      if (accountID) {
        let account = await sdk.fetchAccount(accountID);
        dispatch(setAccountDetails(account));
      }
    }, 7000);
  };

  const listAllMappedProducts = async () => {
    try {
      const response = await API.graphql(
        graphqlOperation(listShopifyProductMappings)
      );
      const accountIDTemp = groups.length > 0 ? groups[0] : null;
      response?.data?.listShopifyProductMappings?.items.map(async (val) => {
        if (accountIDTemp === val?.account) {
          await API.graphql(
            graphqlOperation(deleteShopifyProductMapping, {
              input: {
                id: val?.id,
              },
            })
          );
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const cancelPlan = async () => {
    try {
      const apiName = "blustreamREST";
      const path = "/billing/shopify/cancel";
      await API.post(apiName, path);
    } catch (error) {
      console.log(error);
    }
    setTimeout(async () => {
      fetchAccountDetails();
    }, 2000);
  };

  const removeShopifyProfile = async () => {
    setDisonnectLoading(true);
    try {
      if (accountDetails?.shopifyPlanID?.length > 10) {
        await cancelPlan();
      }
      await listAllMappedProducts();
      setTimeout(async () => {
        await API.graphql(
          graphqlOperation(deleteShopifyProfile, {
            input: { id: shopifyProfileIdValue },
          })
        );
        if (accountDetails?.source === "shopify") {
          await API.graphql(
            graphqlOperation(updateCustomerAccount, {
              input: { id: accountDetails?.id, manualBilling: true },
            })
          );
        }
        getConnectedShopifyProfile();
        setShowShopifyConcentPage(false);
        setIsShopifyProfileConnected(false);
        setShowDisconnectShopifyModal(false);
        setSelectedPlatform("home");
        setDisonnectLoading(false);
        dispatch(setShopifyProfileId({}));
        message.success(
          "Blustream app disconnected successfully from shopify!"
        );
      }, 8000);
    } catch (error) {
      setDisonnectLoading(false);
      console.log(error);
    }
  };

  const getEndpoint = async () => {
    const endpoint = await API.endpoint("blustreamREST");
    setEndpoint(endpoint);
  };

  const generateSecretKey = async () => {
    setSaveTriTechLoading(true);
    const timestamp = new Date().getTime().toString();
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz" + timestamp;
    let result = "";
    for (let i = 0; i < 36; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return result;
  };

  const updateTriTechProfile = async () => {
    const result = await generateSecretKey();
    setUpdateTriTechLoading(true);
    try {
      await sdk
        .updateTriTech(triTechProfileId, result)
        .then(() => {
          setTritechSecretKey(result);
          setUpdateTriTechLoading(false);
          setIsTritechConnected(false);
        })
        .catch((err) => {
          console.log(err);
          setUpdateTriTechLoading(false);
        });
    } catch (error) {
      console.log(error);
      setUpdateTriTechLoading(false);
    }
  };

  const addTritechProfile = async () => {
    const result = await generateSecretKey();
    setSaveTriTechLoading(true);
    try {
      await sdk
        .addTriTech(accountDetails?.id, result)
        .then(async (data) => {
          const tritechProfile = data?.data?.createTriTechProfile;
          await Promise.all(
            triTechEventsArray?.map((event) => {
              return sdk.createTriTechContractAction(tritechProfile?.id, event);
            })
          ).then(() => {
            getConnectedTriTechProfile();
            setTritechSecretKey(result);
            setSaveTriTechLoading(false);
          });
        })
        .catch((err) => {
          console.log(err);
          setSaveTriTechLoading(false);
        });
    } catch (error) {
      console.log(error);
      setSaveTriTechLoading(false);
    }
  };

  const updateTriTechEvent = async () => {
    const tempArray = transactionStatusArray?.map((status) => status.trim());
    await sdk.updateTriTechContractAction(selectedEvent?.id, tempArray);
    getConnectedTriTechProfile();
  };

  const addWhatsappDetails = async (data) => {
    try {
      setWhatsappConfigurationLoader(true);
      const verifyToken = await generateSecretKey();
      const payload = {
        ...data,
        customerId: customerId,
        verifyToken: whatsappConfiguration?.verifyToken
          ? whatsappConfiguration?.verifyToken
          : verifyToken,
      };

      const result = await addWhatsappConfiguration(payload);
      if (result === true) {
        message.success("Your details are saved successfully");
        setIsWhatsappConfigurationChanged(false);
        setWhatsappConfigurationLoader(false);
        getWhatsappConfiguration(customerId).then((res) => {
          if (res?.response?.data && res?.response?.data?.length) {
            const data = res?.response?.data[0];
            setWhatsappConfiguration(data);
            whatsappForm?.setFieldsValue({
              authToken: data?.authToken,
              whatsappBusinessAccountId: data?.whatsappBusinessAccountId,
              phoneNumberId: data?.whatsappPhoneNumberId,
              originationPhoneNumber:
                data?.customerOriginationPhoneNumberDto?.phoneNumber,
            });
          }
        });
      } else {
        message.error("Details are not correct");
        setWhatsappConfigurationLoader(false);
      }
    } catch (error) {
      message.error("Details are not correct");
      setWhatsappConfigurationLoader(false);
    }
  };

  return loading ? (
    <Row justify="center">
      <Col
        span={20}
        style={{
          backgroundColor: "#EFF4F9",
          marginTop: "25px",
          borderRadius: "7px",
        }}
      >
        <Skeleton.Button
          active={true}
          size="default"
          shape="default"
          style={{ width: "956px", height: "145px" }}
        />
      </Col>
      <Col span={20} style={{ marginTop: "30px" }}>
        <Row className="cards-container" gutter={[20, 20]}>
          <Col
            className="card-container"
            span={24}
            sm={12}
            md={12}
            lg={12}
            xl={8}
            xxl={6}
          >
            <Skeleton.Button
              active={true}
              size="default"
              shape="default"
              style={{ width: "305px", height: "170px" }}
            />
          </Col>
          <Col
            className="card-container"
            span={24}
            sm={12}
            md={12}
            lg={12}
            xl={8}
            xxl={6}
          >
            <Skeleton.Button
              active={true}
              size="default"
              shape="default"
              style={{ width: "305px", height: "170px" }}
            />
          </Col>
          <Col
            className="card-container"
            span={24}
            sm={12}
            md={12}
            lg={12}
            xl={8}
            xxl={6}
          >
            <Skeleton.Button
              active={true}
              size="default"
              shape="default"
              style={{ width: "305px", height: "170px" }}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  ) : (
    <div
      style={{
        backgroundColor: "white",
        minHeight: " calc(100vh - 64px)",
        borderRadius: "7px",
        paddingBottom: "20px",
      }}
    >
      {selectedPlatform === "home" && (
        <Row justify="center">
          <Col
            span={20}
            style={{
              backgroundColor: "#EFF4F9",
              marginTop: "25px",
              borderRadius: "7px",
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div
                style={{
                  padding: "25px 0px 0px 28px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography.Title style={{ fontSize: 22 }}>
                  Your Blustream Integration
                </Typography.Title>
                <Typography.Text style={{ paddingBottom: 21, fontSize: 16 }}>
                  Select your channel to start Blustream
                  <br />
                  integration into your system
                </Typography.Text>
              </div>
              <div>
                <img src={IntegartionIcons} style={{ height: 145 }} alt="" />
              </div>
            </div>
          </Col>
          <Col span={20} style={{ marginTop: "30px" }}>
            <Row className="cards-container" gutter={[20, 20]}>
              <Col
                className="card-container"
                span={24}
                sm={12}
                md={12}
                lg={12}
                xl={8}
                xxl={6}
                onClick={() => setSelectedPlatform("shopify")}
              >
                <div className="platform-card" style={{ position: "relative" }}>
                  <img className="shopify-icon" src={Shopify} alt="" />
                  <p className="plateform-name">Shopify</p>
                  {isShopifyProfileConnected ? (
                    <div
                      style={{
                        position: "absolute",
                        bottom: "10px",
                      }}
                    >
                      <img src={ActivateLogo} alt="" />
                      <span
                        style={{
                          color: "#25B291",
                          fontSize: 16,
                          marginLeft: 7,
                        }}
                      >
                        Active
                      </span>
                    </div>
                  ) : null}
                </div>
              </Col>
              <Col
                className="card-container"
                span={24}
                sm={12}
                md={12}
                lg={12}
                xl={8}
                xxl={6}
                onClick={() => setSelectedPlatform("tritech")}
              >
                <div className="platform-card">
                  <img className="tritech-icon" src={TriTech} alt="" />
                  <p className="plateform-name">Tri-Tech</p>
                  {isTritechConnected ? (
                    <div
                      style={{
                        position: "absolute",
                        bottom: "10px",
                      }}
                    >
                      <img src={ActivateLogo} alt="" />
                      <span
                        style={{
                          color: "#25B291",
                          fontSize: 16,
                          marginLeft: 7,
                        }}
                      >
                        Active
                      </span>
                    </div>
                  ) : null}
                </div>
              </Col>
              <Col
                className="card-container"
                span={24}
                sm={12}
                md={12}
                lg={12}
                xl={8}
                xxl={6}
                onClick={() => setSelectedPlatform("whatsapp")}
              >
                <div className="platform-card" style={{ position: "relative" }}>
                  <img
                    className="whatsapp-icon"
                    src={whatsapp}
                    alt="whatsapp"
                  />
                  <p className="plateform-name">WhatsApp</p>
                  {whatsappConfiguration?.webhookVerified ? (
                    <div
                      style={{
                        position: "absolute",
                        bottom: "10px",
                      }}
                    >
                      <img src={ActivateLogo} alt="" />
                      <span
                        style={{
                          color: "#25B291",
                          fontSize: 16,
                          marginLeft: 7,
                        }}
                      >
                        Active
                      </span>
                    </div>
                  ) : null}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      )}

      {selectedPlatform === "shopify" &&
        (showShopifyConcentPage ? (
          <>
            <Row justify="center">
              <Col span={24} className="shopify-integration-header-container">
                <div className="arrow-title-container">
                  <img
                    className="back-arrow"
                    src={backArrow}
                    alt=""
                    onClick={() => {
                      setSelectedPlatform("home");
                      getConnectedShopifyProfile();
                    }}
                  />
                  <div className="platform-page-title-container">
                    Shopify Integration
                  </div>
                </div>
                <div className="shopify-header-buttons-container">
                  <div>
                    <img src={ActivateLogo} alt="" />
                    <span
                      style={{
                        color: "#25B291",
                        fontSize: 16,
                        marginLeft: 7,
                      }}
                    >
                      {shopifyStoreName}
                    </span>
                  </div>
                  <a
                    className="standard-button primary-orange-button"
                    rel="noreferrer"
                    href={`https://admin.shopify.com/store/${shopifyDomain}/`}
                    target="_blank"
                  >
                    Go to your Shopify store
                  </a>
                </div>
              </Col>
              <Divider className="platform-title-divider" />
            </Row>
            <Row justify="center" className="integaration-concent-text-page">
              <Col span={17}>
                <div className="tcpa-section">
                  <div className="tcpa-text-section">
                    <img src={protectedFileIcon} alt="protect-file-icon" />
                    <p className="tcpa-text">
                      Under TCPA and several other state-level laws, contacts
                      need to opt in to messages. Don’t worry, we’ve got you
                      covered.
                    </p>
                  </div>
                  <a
                    href="https://www.fcc.gov/general/telemarketing-and-robocalls"
                    className="standard-button tertiary-button"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Read more
                  </a>
                </div>

                <div className="native-sms-title">
                  Setup
                </div>
                <p className="native-sms-subtitle">
                  Opt-In Checkbox
                </p>
                <p className="native-sms-text">
                  To automatically load contacts into Blustream upon checkout, enable the SMS marketing in your Shopify store. Once enabled, contacts will have the option to opt-in during the checkout process using Shopify's native SMS opt-in tool.
                </p>

                <p className="native-sms-status-title">
                  Status:{" "}
                  {shopifyStoreSmsMarketingEnabledStatus === "LOADING" ? (<span>Loading</span>) : null}
                  {shopifyStoreSmsMarketingEnabledStatus === "ENABLED" ? (<span className="enabled">Enabled</span>) : null}
                  {shopifyStoreSmsMarketingEnabledStatus === "DISABLED" ? (<span className="disabled">Disabled</span>) : null}
                  {shopifyStoreSmsMarketingEnabledStatus === "ERROR" ? (<span className="disabled">Error</span>) : null}
                </p>

                <div className="native-sms-button-container">
                  <Button
                    className="standard-button secondary-orange-button"
                    onClick={() => {
                      window.open(
                        `https://admin.shopify.com/store/${shopifyDomain}/settings/checkout`
                      );
                    }}
                    disabled={roAdmin}
                  >
                    Go to SMS Opt-In Settings
                  </Button>
                  <Button
                    className="standard-button secondary-orange-button"
                    onClick={() => {
                      checkAndUpdateStoreSmsMarketingEnabled();
                    }}
                    disabled={roAdmin}
                  >
                    Refresh Status
                  </Button>
                  <p
                    className="native-sms-modal-link"
                    onClick={() => setShowShopifyNativeConcentModal(true)}
                  >
                    Show me how
                  </p>
                </div>

                <br/>

                <p className="native-sms-subtitle">
                  Consent Language
                </p>
                    
                <p className="native-sms-text">
                Configure your opt-in message to encourage contacts to sign up for journeys. Incentives go a long way in encouraging signups.
                </p>

                <div className="native-sms-button-container">
                  <Button
                    className="standard-button secondary-orange-button"
                    onClick={() => {
                      window.open(
                        `https://admin.shopify.com/store/${shopifyDomain}/themes`
                      );
                    }}
                    disabled={roAdmin}
                  >
                    Go to SMS Consent Language
                  </Button>
                  <p
                    className="native-sms-modal-link"
                    onClick={() => setShowShopifyNativeConcentModal(true)}
                  >
                    Show me how
                  </p>
                </div>



                <Divider
                  style={{
                    border: "0.5px solid #D9EAFB",
                    marginBottom: "20px",
                  }}
                />
                
                <div className="disconnect-box">
                  <div className="disconnect-content-container">
                    <div>
                      <img src={disconnectIcon} alt="delete-link-icon" />
                    </div>
                    <div className="disconnect-text-container">
                      <p className="disconnect-title">
                        Disconnect Blustream app from Shopify
                      </p>
                      <p className="disconnect-text">
                        Once you disconnect your app from Shopify, all your
                        products linked with Blustream will be removed!
                      </p>
                    </div>
                  </div>
                  <Button
                    className="standard-button disconnect-button"
                    onClick={() => setShowDisconnectShopifyModal(true)}
                    disabled={roAdmin}
                  >
                    Disconnect app
                  </Button>
                </div>
              </Col>
            </Row>
          </>
        ) : (
          <>
            <Row justify="center">
              <Col span={24} className="shopify-integration-header-container">
                <div className="arrow-title-container">
                  <img
                    className="back-arrow"
                    src={backArrow}
                    alt=""
                    onClick={() => {
                      if (!isShopifyProfileConnected) {
                        setSelectedPlatform("home");
                      } else {
                        setShowShopifyConcentPage(true);
                      }
                    }}
                  />
                  <div className="platform-page-title-container">
                    Steps to connect to Shopify store
                  </div>
                </div>
                <div className="shopify-header-buttons-container">
                  {roAdmin ? (
                    <Button className="connect-shopify" disabled={true}>
                      Connect To Shopify
                    </Button>
                  ) : !isShopifyProfileConnected ? (
                    <a
                      href="https://apps.shopify.com/blustream"
                      type="primary"
                      className="standard-button primary-orange-button"
                    >
                      Connect To Shopify
                    </a>
                  ) : null}
                </div>
              </Col>
              <Divider className="platform-title-divider" />
            </Row>
            <Row className="shopify-disconnect-main-Row" justify="center">
              <Col span={20} className="shopify-disconnect-main-Col">
                <Row justify="center">
                  <Col span={14}>
                    <img
                      className="shopify-disconnect-image"
                      src={disconnectShopify1}
                      alt="shopifySlide1"
                    />
                  </Col>
                  <Col span={10} className="shopify-disconnect-sub-Col">
                    <Row justify="center">
                      <Col span={20}>
                        <h1 className="shopify-disconnect-step-title">
                          Step 1
                        </h1>
                        <p className="shopify-disconnect-step-text">
                          To get started connect Blustream to Shopify store.
                          Click on the ‘Connect to Shopify’ button from top
                          right.
                        </p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row justify="center">
                  <Col span={14}>
                    <img
                      className="shopify-disconnect-image"
                      src={disconnectShopify2}
                      alt="shopifySlide2"
                    />
                  </Col>
                  <Col span={10} className="shopify-disconnect-sub-Col">
                    <Row justify="center">
                      <Col span={20}>
                        <h1 className="shopify-disconnect-step-title">
                          Step 2
                        </h1>
                        <p className="shopify-disconnect-step-text">
                          Click on ‘Add app’ button to redirect to your Shopify
                          store.
                        </p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row justify="center">
                  <Col span={14}>
                    <img
                      className="shopify-disconnect-image"
                      src={disconnectShopify3}
                      alt="shopifySlide3"
                    />
                  </Col>
                  <Col span={10} className="shopify-disconnect-sub-Col">
                    <Row justify="center">
                      <Col span={20}>
                        <h1 className="shopify-disconnect-step-title">
                          Step 3
                        </h1>
                        <p className="shopify-disconnect-step-text">
                          Once you click on install button, you will be
                          redirected to Blustream dashboard after login.
                        </p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </>
        ))}

      {selectedPlatform === "tritech" &&
        (showEventConfigurationPage ? (
          <>
            <Row justify="center">
              <Col span={24} style={{ position: "relative" }}>
                <div className="tritech-platform-page-title-container">
                  <img
                    src={backArrow}
                    style={{ cursor: "pointer" }}
                    alt=""
                    onClick={() => {
                      setShowEventConfigurationPage(false);
                    }}
                  />
                  <div>Configure Events</div>
                </div>
              </Col>
              <Divider className="platform-title-divider" />
            </Row>
            <Row justify="center" className="tritech-event-main-row">
              {triTechEvents &&
                triTechEvents?.map((event) => {
                  return event?.action !== "AIM_BLUSTREAM_HANDSHAKE" ? (
                    <Col span={20} className="tritech-event-main-col">
                      <div>
                        <img
                          className="edit-icon"
                          src={pen}
                          alt="pen"
                          onClick={() => {
                            setselectedEvent(event);
                            setTransactionStatusArray(
                              event?.payloadTransactionStatus
                            );
                            const formValues = {};
                            event?.payloadTransactionStatus?.forEach(
                              (value, index) => {
                                formValues[`status${index}`] = value;
                              }
                            );
                            tritechEventConfigureForm.setFieldsValue(
                              formValues
                            );
                            setShowTriTechEventConfigureModal(true);
                          }}
                        />
                      </div>
                      <Row>
                        <Col span={8}>
                          <span className="action-title">Action</span>
                        </Col>
                        <Col span={8}>
                          <div className="action-value">{event?.action}</div>
                        </Col>
                        <Col span={8}></Col>
                      </Row>
                      {/* <Row>
                        <Col span={8}>
                          <span className="description-title">Description</span>
                        </Col>
                        <Col span={8}>
                          <div className="description-value">
                            {event?.payloadDescription}
                          </div>
                        </Col>
                        <Col span={8}></Col>
                      </Row> */}
                      <Row>
                        <Col span={8}>
                          <span className="transaction-status-title">
                            Transaction Status
                          </span>
                        </Col>
                        <Col span={8}>
                          <div className="transaction-status-values-container">
                            {event?.payloadTransactionStatus?.map((status) => {
                              return (
                                <div className="transaction-status-value">
                                  {status}
                                </div>
                              );
                            })}
                          </div>
                        </Col>
                        <Col span={8}></Col>
                      </Row>
                    </Col>
                  ) : null;
                })}
            </Row>
          </>
        ) : (
          <>
            <Row justify="center">
              <Col span={24} style={{ position: "relative" }}>
                <div className="tritech-platform-page-title-container">
                  <img
                    src={backArrow}
                    style={{ cursor: "pointer" }}
                    alt=""
                    onClick={() => {
                      setSelectedPlatform("home");
                      setShowSecretKey(false);
                    }}
                  />
                  <div>Tri-Tech Integration</div>
                </div>
              </Col>
              <Divider className="platform-title-divider" />
            </Row>
            <Row justify="center" style={{ marginTop: "25px" }}>
              <Col span={20}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: "32px",
                  }}
                >
                  {isTritechConnected ? (
                    <div
                      className="tritech-verify-message-container"
                      style={{
                        backgroundColor: "#e4f5f1",
                      }}
                    >
                      <img
                        className="tritech-message-icon"
                        src={verifiedIcon}
                        alt="verifiedIcon"
                      />
                      <p className="tritech-verified-message">
                        Your Tri-Tech account is synced with Blustream
                        Successfully!
                      </p>
                    </div>
                  ) : (
                    <div
                      className="tritech-verify-message-container"
                      style={{
                        backgroundColor: "#FFE9E9",
                      }}
                    >
                      <img
                        className="tritech-message-icon"
                        src={notVerifiedIcon}
                        alt="notVerifiedIcon"
                      />
                      <p className="tritech-not-verified-message">
                        Your Tri-Tech account is not synced with Blustream!
                      </p>
                    </div>
                  )}
                </div>
                <Row gutter={[0, 16]}>
                  <Col span={24} xl={12} style={{ paddingRight: "4rem" }}>
                    <h1 className="tritech-step-title">Step 1</h1>
                    <p className="tritech-step-text">
                      Copy this{" "}
                      <span style={{ fontWeight: "600" }}>API endpoint</span>{" "}
                      and paste it in the AIM Blustream integration settings.
                    </p>
                  </Col>
                  <Col
                    span={24}
                    xl={12}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "12px",
                    }}
                  >
                    <div className="endpoint-wrapper">
                      <p className="endpoint-title">Contract Events:</p>
                      <Paragraph
                        className="copy-container"
                        copyable={{
                          icon: [
                            <img
                              className="copy-icon"
                              src={copyIcon}
                              alt="copy-icon"
                              key="copy-icon"
                            />,
                            <img
                              className="copy-icon"
                              src={copyIcon}
                              alt="copy-icon"
                              key="copied-icon"
                            />,
                          ],
                        }}
                      >
                        {`${endpoint}/integrations/tritech/events`}
                      </Paragraph>
                    </div>
                  </Col>
                </Row>
                <Divider style={{ border: "0.5px solid #D9EAFB" }} />
                <Row gutter={[0, 16]}>
                  <Col span={24} xl={12} style={{ paddingRight: "4rem" }}>
                    <h1 className="tritech-step-title">Step 2</h1>
                    <p className="tritech-step-text">
                      Copy this{" "}
                      <span style={{ fontWeight: "600" }}>secret key</span> and
                      paste it in the AIM Blustream integration settings.
                    </p>
                  </Col>
                  <Col
                    span={24}
                    xl={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {tritechSecretKey?.length > 0 ? (
                      <div className="secret-key-main-container">
                        <div>
                          <div className="secret-key-container">
                            {!showSecretKey ? (
                              <Button
                                className="standard-button secondary-dark-blue-button reveal-secret-key-button"
                                onClick={() => {
                                  setShowSecretKey(true);
                                }}
                                //checking that admin is scoped or not.
                                disabled={isScoped || roAdmin}
                              >
                                Reveal secret key
                              </Button>
                            ) : null}
                            <Paragraph
                              className={`copy-container ${
                                showSecretKey ? "" : "blur-box"
                              }`}
                              style={{
                                userSelect:
                                  isScoped || roAdmin ? "none" : "auto",
                              }}
                              copyable={
                                showSecretKey
                                  ? {
                                      icon: [
                                        <img
                                          className="copy-icon"
                                          src={copyIcon}
                                          alt="copy-icon"
                                          key="copy-icon"
                                        />,
                                        <img
                                          className="copy-icon"
                                          src={copyIcon}
                                          alt="copy-icon"
                                          key="copied-icon"
                                        />,
                                      ],
                                    }
                                  : false
                              }
                            >
                              {tritechSecretKey}
                            </Paragraph>
                          </div>
                        </div>
                        {showSecretKey ? (
                          <Button
                            className="standard-button secondary-dark-blue-button generate-new-secret-key"
                            onClick={() => setKeyUpdateWarningModal(true)}
                            style={{ width: "fit-content" }}
                          >
                            Generate New Secret Key
                          </Button>
                        ) : null}
                      </div>
                    ) : (
                      <Button
                        className="standard-button secondary-dark-blue-button"
                        onClick={addTritechProfile}
                        loading={saveTriTechLoading}
                        disabled={isScoped || roAdmin}
                      >
                        Generate Secret Key
                      </Button>
                    )}
                  </Col>
                </Row>
                <Divider style={{ border: "0.5px solid #D9EAFB" }} />
                <Row gutter={[0, 16]}>
                  <Col span={24} xl={12} style={{ paddingRight: "4rem" }}>
                    <h1 className="tritech-step-title">Step 3</h1>
                    <p className="tritech-step-text">
                      Copy your{" "}
                      <span style={{ fontWeight: "600" }}>
                        Blustream account ID
                      </span>{" "}
                      and paste it in the AIM Blustream integration settings.
                    </p>
                  </Col>
                  <Col
                    span={24}
                    xl={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Paragraph
                      className="copy-container"
                      copyable={{
                        icon: [
                          <img
                            className="copy-icon"
                            src={copyIcon}
                            alt="copy-icon"
                            key="copy-icon"
                          />,
                          <img
                            className="copy-icon"
                            src={copyIcon}
                            alt="copy-icon"
                            key="copied-icon"
                          />,
                        ],
                      }}
                    >
                      {accountDetails?.id}
                    </Paragraph>
                  </Col>
                </Row>
                <Divider style={{ border: "0.5px solid #D9EAFB" }} />
                <Row>
                  <Col span={24}>
                    <h1 className="tritech-step-title">Step 4</h1>
                    <p className="tritech-step-text">
                      Once you add the API endpoints, secret key, and Blustream
                      account ID in AIM, press{" "}
                      <span style={{ fontWeight: "600", color: "#3B84D1" }}>
                        ‘Save’
                      </span>{" "}
                      button.
                    </p>
                  </Col>
                </Row>
                <Divider style={{ border: "0.5px solid #D9EAFB" }} />
                <Row gutter={[0, 16]}>
                  <Col span={24} xl={12} style={{ paddingRight: "4rem" }}>
                    <h1 className="tritech-step-title">Step 5</h1>
                    <p className="tritech-step-text">
                      After{" "}
                      <span style={{ fontWeight: "600" }}>
                        completing AIM steps
                      </span>{" "}
                      you are good to go for configuration
                    </p>
                  </Col>
                  <Col
                    span={24}
                    xl={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      className="standard-button secondary-dark-blue-button"
                      disabled={tritechSecretKey?.length <= 0}
                      onClick={() => {
                        setShowEventConfigurationPage(true);
                      }}
                    >
                      Configure Events
                    </Button>
                  </Col>
                </Row>
                <Divider style={{ border: "0.5px solid #D9EAFB" }} />
                <p className="tritech-note-text">
                  You will receive a success message in the top once the account
                  details are synced successfully.
                </p>
              </Col>
            </Row>
          </>
        ))}

      {selectedPlatform === "whatsapp" && (
        <>
          <Row justify="center">
            <Col span={24} style={{ position: "relative" }}>
              <div className="tritech-platform-page-title-container">
                <img
                  src={backArrow}
                  style={{ cursor: "pointer" }}
                  alt=""
                  onClick={() => {
                    setSelectedPlatform("home");
                    setWhatsappSlider(0);
                    slider3?.current?.goTo(0);
                    if (whatsappConfiguration) {
                      whatsappForm.setFieldsValue({
                        authToken: whatsappConfiguration?.authToken,
                        whatsappBusinessAccountId:
                          whatsappConfiguration?.whatsappBusinessAccountId,
                        phoneNumberId:
                          whatsappConfiguration?.whatsappPhoneNumberId,
                        originationPhoneNumber:
                          whatsappConfiguration
                            ?.customerOriginationPhoneNumberDto?.phoneNumber,
                      });
                    } else {
                      whatsappForm.resetFields();
                    }
                    setIsWhatsappConfigurationChanged(false);
                  }}
                />
                <div>WhatsApp Integration</div>
              </div>
            </Col>
            <Divider className="platform-title-divider" />
          </Row>
          <Row justify="center" style={{ marginTop: "25px" }}>
            <Col span={22}>
              {whatsappConfiguration?.webhookVerified && (
                <div className="whatsapp-success-message-container">
                  <div
                    className="tritech-verify-message-container"
                    style={{
                      backgroundColor: "#e4f5f1",
                    }}
                  >
                    <img
                      className="tritech-message-icon"
                      src={verifiedIcon}
                      alt="verifiedIcon"
                    />
                    <p className="tritech-verified-message">
                      Your WhatsApp account is synced with blustream!
                    </p>
                  </div>
                </div>
              )}
              <div className="whatsapp-guidence-text-container">
                <img src={openBook} alt="openBook" />
                <p className="whatsapp-guidence-text">
                  Before continuing integration, you just need to have an
                  account in Meta Developer & Meta Business & if you don’t have
                  any account created, we are happy to guide you.
                </p>
              </div>
              <Collapse
                accordion
                className="shopify-accordians-container"
                expandIconPosition="right"
                bordered={false}
                defaultActiveKey={
                  whatsappConfiguration?.verifyToken ? ["2"] : []
                }
              >
                <Panel
                  header={
                    <div className="accordian-header-container">
                      <div className="accordian-title">
                        <span className="dark-text">
                          No, I don't have a Meta Account
                        </span>
                      </div>
                    </div>
                  }
                  key="1"
                >
                  <Carousel dots={false} ref={slider3} adaptiveHeight={true}>
                    <div className="whatsapp-slide">
                      <div className="slide-center-container">
                        <div className="whatsapp-slide-text-container">
                          <div className="whatsapp-slide-text-title">
                            Step 1: Setup an Account
                          </div>
                          <div className="whatsapp-slide-text">
                            Create a Facebook (meta) developer account
                            (https://developers.facebook.com/), If you have it
                            log into it.
                          </div>
                        </div>
                        <img src={whatsappSlide1} alt="whatsappSlide1" />
                      </div>
                    </div>
                    <div className="whatsapp-slide">
                      <div className="slide-center-container">
                        <div className="whatsapp-slide-text-container">
                          <div className="whatsapp-slide-text-title">
                            Step 2: Create App
                          </div>
                          <div className="whatsapp-slide-text">
                            Create ‘New’ App
                          </div>
                        </div>
                        <img src={whatsappSlide2} alt="whatsappSlide2" />
                      </div>
                    </div>
                    <div className="whatsapp-slide">
                      <div className="slide-center-container">
                        <div className="whatsapp-slide-text-container">
                          <div className="whatsapp-slide-text-title">
                            Step 3: Create App
                          </div>
                          <div className="whatsapp-slide-text">
                            Select ‘Other’ Option
                          </div>
                        </div>
                        <img src={whatsappSlide3} alt="whatsappSlide3" />
                      </div>
                    </div>
                    <div className="whatsapp-slide">
                      <div className="slide-center-container">
                        <div className="whatsapp-slide-text-container">
                          <div className="whatsapp-slide-text-title">
                            Step 4: Select App Type
                          </div>
                          <div className="whatsapp-slide-text">
                            Select ‘Business’ Option
                          </div>
                        </div>
                        <img src={whatsappSlide4} alt="whatsappSlide4" />
                      </div>
                    </div>
                    <div className="whatsapp-slide">
                      <div className="slide-center-container">
                        <div className="whatsapp-slide-text-container">
                          <div className="whatsapp-slide-text-title">
                            Step 5: Fill App Details
                          </div>
                          <div className="whatsapp-slide-text">
                            Add App ‘Name’ & ‘Contact Email’
                          </div>
                        </div>
                        <img src={whatsappSlide5} alt="whatsappSlide5" />
                      </div>
                    </div>
                    <div className="whatsapp-slide">
                      <div className="slide-center-container">
                        <div className="whatsapp-slide-text-container">
                          <div className="whatsapp-slide-text-title">
                            Step 6: Integrate with WhatsApp
                          </div>
                          <div className="whatsapp-slide-text">
                            Now add the product to your app (In this case click
                            on setup ‘WhatsApp’)
                          </div>
                        </div>
                        <img src={whatsappSlide6} alt="whatsappSlide6" />
                      </div>
                    </div>

                    <div className="whatsapp-slide">
                      <div className="slide-center-container">
                        <div className="whatsapp-slide-text-container">
                          <div className="whatsapp-slide-text-title">
                            Step 7: WhatsApp Quickstart
                          </div>
                          <div className="whatsapp-slide-text">
                            1. Now we need to set up WhatsApp API and click on
                            the quick start.
                          </div>
                          <div className="whatsapp-slide-text">
                            2. Select a business account and press the
                            ‘Continue’ button. If you don't have one create a
                            new one, it may take a few days to approve.
                          </div>
                        </div>
                        <img src={whatsappSlide7} alt="whatsappSlide7" />
                      </div>
                    </div>
                    <div className="whatsapp-slide">
                      <div className="slide-center-container">
                        <div className="whatsapp-slide-text-container">
                          <div className="whatsapp-slide-text-title">
                            Step 8: WhatsApp Configuration
                          </div>
                          <div className="whatsapp-slide-text">
                            Now configure webhook, blustream will provide you
                            Callback URL and verify the token -
                          </div>
                          <div className="whatsapp-slide-text">
                            • Click on ‘Edit’ button.
                          </div>
                        </div>
                        <img src={whatsappSlide8} alt="whatsappSlide8" />
                      </div>
                    </div>
                    <div className="whatsapp-slide">
                      <div className="slide-center-container">
                        <div className="whatsapp-slide-text-container">
                          <div className="whatsapp-slide-text-title">
                            Step 9: WhatsApp Configuration Verify
                          </div>
                          <div className="whatsapp-slide-text">
                            Copy the webhook URL and token from ‘Blustream
                            Application - Integration - WhatsApp page’ and paste
                            them here & press ‘Verify and Save’ button.
                          </div>
                        </div>
                        <img src={whatsappSlide9} alt="whatsappSlide9" />
                      </div>
                    </div>
                  </Carousel>
                  <div className="whatsapp-slider-controls">
                    <Button
                      className={`slider-button ${
                        whatsappSlider === 0 ? "current-slide" : ""
                      }`}
                      onClick={() => {
                        setWhatsappSlider(0);
                        slider3.current.goTo(0);
                      }}
                    >
                      1
                    </Button>
                    <Button
                      className={`slider-button ${
                        whatsappSlider === 1 ? "current-slide" : ""
                      }`}
                      onClick={() => {
                        setWhatsappSlider(1);
                        slider3.current.goTo(1);
                      }}
                    >
                      2
                    </Button>
                    <Button
                      className={`slider-button ${
                        whatsappSlider === 2 ? "current-slide" : ""
                      }`}
                      onClick={() => {
                        setWhatsappSlider(2);
                        slider3.current.goTo(2);
                      }}
                    >
                      3
                    </Button>
                    <Button
                      className={`slider-button ${
                        whatsappSlider === 3 ? "current-slide" : ""
                      }`}
                      onClick={() => {
                        setWhatsappSlider(3);
                        slider3.current.goTo(3);
                      }}
                    >
                      4
                    </Button>
                    <Button
                      className={`slider-button ${
                        whatsappSlider === 4 ? "current-slide" : ""
                      }`}
                      onClick={() => {
                        setWhatsappSlider(4);
                        slider3.current.goTo(4);
                      }}
                    >
                      5
                    </Button>
                    <Button
                      className={`slider-button ${
                        whatsappSlider === 5 ? "current-slide" : ""
                      }`}
                      onClick={() => {
                        setWhatsappSlider(5);
                        slider3.current.goTo(5);
                      }}
                    >
                      6
                    </Button>
                    <Button
                      className={`slider-button ${
                        whatsappSlider === 6 ? "current-slide" : ""
                      }`}
                      onClick={() => {
                        setWhatsappSlider(6);
                        slider3.current.goTo(6);
                      }}
                    >
                      7
                    </Button>
                    <Button
                      className={`slider-button ${
                        whatsappSlider === 7 ? "current-slide" : ""
                      }`}
                      onClick={() => {
                        setWhatsappSlider(7);
                        slider3.current.goTo(7);
                      }}
                    >
                      8
                    </Button>
                    <Button
                      className={`slider-button ${
                        whatsappSlider === 8 ? "current-slide" : ""
                      }`}
                      onClick={() => {
                        setWhatsappSlider(8);
                        slider3.current.goTo(8);
                      }}
                    >
                      9
                    </Button>
                  </div>
                </Panel>
                <Panel
                  header={
                    <div className="accordian-header-container">
                      <div className="accordian-title">
                        <span className="dark-text">
                          Yes, I do have a Meta Account
                        </span>
                      </div>
                    </div>
                  }
                  key="2"
                >
                  <Row justify="center">
                    <Col span={23} className="whatsapp-accordian-2">
                      <h2 className="whatsapp-step-title">Step 1</h2>
                      <p className="whatsapp-step-guidence-text">
                        Copy the below-required details from the{" "}
                        <span className="highlighted-text">
                          <a
                            href="https://developers.facebook.com/apps/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            developers.facebook.com/apps/
                          </a>{" "}
                          &gt; WhatsApp &gt; Configuration
                        </span>{" "}
                        and paste them here.
                      </p>

                      <Form
                        form={whatsappForm}
                        className="whatsapp-integration-form"
                        layout="vertical"
                        onValuesChange={() => {
                          setIsWhatsappConfigurationChanged(true);
                          const formValues = whatsappForm?.getFieldsValue();
                          whatsappForm?.setFieldsValue({
                            originationPhoneNumber:
                              formValues?.originationPhoneNumber?.trimStart(),
                            phoneNumberId:
                              formValues?.phoneNumberId?.trimStart(),
                            whatsappBusinessAccountId:
                              formValues?.whatsappBusinessAccountId?.trimStart(),
                            authToken: formValues?.authToken?.trimStart(),
                          });
                        }}
                        onFinish={(data) => {
                          addWhatsappDetails(data);
                        }}
                      >
                        <Row gutter={[30, 0]}>
                          <Col span={10}>
                            <Form.Item
                              label="1. Origination Phone Number"
                              name="originationPhoneNumber"
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter Phone Number",
                                },
                                {
                                  pattern:
                                    /^\+?([\\(\\)\s-]*\d[\\(\\)]*){10,13}$/,
                                  message: "Please enter valid Phone Number",
                                },
                              ]}
                            >
                              <Input
                                className="standard-input"
                                placeholder="+1 789 675 2334"
                                disabled={whatsappConfiguration?.verifyToken}
                              ></Input>
                            </Form.Item>
                          </Col>
                          <Col span={10}>
                            <Form.Item
                              label="2. Phone Number ID"
                              name="phoneNumberId"
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter Phone Number ID",
                                },
                              ]}
                            >
                              <Input
                                className="standard-input"
                                placeholder="1089767686868887797"
                                disabled={whatsappConfiguration?.verifyToken}
                              ></Input>
                            </Form.Item>
                          </Col>
                          <Col span={10}>
                            <Form.Item
                              label="3. WhatsApp Business Account ID"
                              name="whatsappBusinessAccountId"
                              rules={[
                                {
                                  required: true,
                                  message:
                                    "Please enter WhatsApp Business Account ID",
                                },
                              ]}
                            >
                              <Input
                                className="standard-input"
                                placeholder="14768786784343437797"
                                disabled={whatsappConfiguration?.verifyToken}
                              ></Input>
                            </Form.Item>
                          </Col>
                          <Col span={10}>
                            <Form.Item
                              label="4. Authentication Token"
                              name="authToken"
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter Authentication Token",
                                },
                              ]}
                            >
                              <Input.Password
                                iconRender={(visible) =>
                                  whatsappConfiguration?.verifyToken ? null : visible ? (
                                    <EyeTwoTone />
                                  ) : (
                                    <EyeInvisibleOutlined />
                                  )
                                }
                                disabled={whatsappConfiguration?.verifyToken}
                                className="standard-input"
                                placeholder="5hdjhd888g3432-2cbe-11ee-9819-9fef3450cf5e"
                              ></Input.Password>
                            </Form.Item>
                          </Col>
                          <Col
                            span={10}
                            className="whatsapp-verified-button-container"
                          >
                            <Form.Item>
                              <Button
                                className="standard-button primary-orange-button"
                                htmlType="submit"
                                disabled={
                                  whatsappConfiguration &&
                                  !isWhatsappConfigurationChanged
                                }
                                loading={whatsappConfigurationLoader}
                              >
                                Verify & Continue
                              </Button>
                              {whatsappConfiguration && (
                                <div className="verified-container">
                                  <img
                                    className="verified-icon"
                                    src={verifiedIcon}
                                    alt="verifiedIcon"
                                  />
                                  <span className="tritech-verified-message">
                                    verified
                                  </span>
                                </div>
                              )}
                            </Form.Item>
                          </Col>
                        </Row>
                      </Form>
                      <div>
                        <Divider className="whatsapp-step-divider" />
                        <h2 className="whatsapp-step-title">Step 2</h2>
                        <p className="whatsapp-step-guidence-text">
                          Once you complete the First Step copy the below
                          Webhook URL and Token, and paste them into the website{" "}
                          <span className="highlighted-text">
                            <a
                              href="https://developers.facebook.com/apps/"
                              target="_blank"
                              rel="noreferrer"
                            >
                              developers.facebook.com/apps/
                            </a>{" "}
                            &gt; WhatsApp &gt; Configuration.
                          </span>{" "}
                          Press the “Verify & Save” button on the Facebook
                          developers application. Once verified proceed to press
                          the “Verify & Save” button here to complete this Step.
                          (Please note that the Webhook URL and Token Fields
                          will be disabled until you complete Step 1).
                        </p>
                        <Row
                          gutter={[16, 8]}
                          className="whatsapp-copiable-content-wrapper"
                        >
                          <Col
                            span={24}
                            xl={4}
                            className="whatsapp-copiable-content-title"
                          >
                            Your Webhook URL :
                          </Col>
                          {whatsappConfiguration?.verifyToken ? (
                            <Col span={24} xl={20}>
                              <Paragraph
                                className="copy-container whatsapp-callback-container"
                                copyable={{
                                  icon: [
                                    <img
                                      className="copy-icon"
                                      src={copyIcon}
                                      alt="copy-icon"
                                      key="copy-icon"
                                    />,
                                    <img
                                      className="copy-icon"
                                      src={copyIcon}
                                      alt="copy-icon"
                                      key="copied-icon"
                                    />,
                                  ],
                                }}
                              >
                                {`${endpoint}/gandalfproxy/api/whatsapp/webhook`}
                              </Paragraph>
                            </Col>
                          ) : (
                            <Col span={10}>
                              <div className="whatsapp-disable-box"></div>
                            </Col>
                          )}
                        </Row>
                        <Row
                          gutter={[16, 8]}
                          className="whatsapp-copiable-content-wrapper"
                        >
                          <Col
                            span={24}
                            xl={4}
                            className="whatsapp-copiable-content-title"
                          >
                            Your Verify Token :
                          </Col>
                          {whatsappConfiguration?.verifyToken ? (
                            <Col span={24} xl={20}>
                              <Paragraph
                                className="copy-container whatsapp-callback-container"
                                copyable={{
                                  icon: [
                                    <img
                                      className="copy-icon"
                                      src={copyIcon}
                                      alt="copy-icon"
                                      key="copy-icon"
                                    />,
                                    <img
                                      className="copy-icon"
                                      src={copyIcon}
                                      alt="copy-icon"
                                      key="copied-icon"
                                    />,
                                  ],
                                }}
                              >
                                {whatsappConfiguration?.verifyToken
                                  ? whatsappConfiguration?.verifyToken
                                  : ""}
                              </Paragraph>
                            </Col>
                          ) : (
                            <Col span={10}>
                              <div className="whatsapp-disable-box"></div>
                            </Col>
                          )}
                        </Row>
                        <div className="whatsapp-step2-verified-button-container">
                          <Button
                            className="standard-button primary-orange-button"
                            onClick={() => {
                              try {
                                setWhatsappWebhookVerifyLoader(true);
                                getWhatsappConfiguration(customerId).then(
                                  (res) => {
                                    if (
                                      res?.response?.data &&
                                      res?.response?.data?.length
                                    ) {
                                      const data = res?.response?.data[0];
                                      setWhatsappConfiguration(data);
                                    }
                                    setWhatsappWebhookVerifyLoader(false);
                                    if (
                                      res?.response?.data?.[0]?.webhookVerified
                                    ) {
                                      message.success("Verified successfully!");
                                    } else {
                                      message.error("Verification failed!");
                                    }
                                  }
                                );
                              } catch (error) {
                                message.error("Something went wrong!");
                                setWhatsappWebhookVerifyLoader(false);
                              }
                            }}
                            disabled={
                              !whatsappConfiguration?.verifyToken ||
                              whatsappConfiguration?.webhookVerified
                            }
                            loading={whatsappWebhookVerifyLoader}
                          >
                            Verify & Save
                          </Button>
                          {whatsappConfiguration?.webhookVerified && (
                            <div className="verified-container">
                              <img
                                className="verified-icon"
                                src={verifiedIcon}
                                alt="verifiedIcon"
                              />
                              <span className="tritech-verified-message">
                                verified
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Panel>
              </Collapse>
            </Col>
          </Row>
        </>
      )}

      <Modal
        centered
        visible={warningModalVisible}
        footer={null}
        className="mapped-warning-modal-container delete-attribute-modal"
        onCancel={() => setWarningModalVisible(false)}
      >
        <div className="mapped-warning-modal">
          <img src={glowingBulbIcon} alt="" />
          <p className="warning-modal-dark-text">
            Are you sure you want to delete this attribute? You can’t undo this
            action.
          </p>
          <div className="mapped-warning-modal-container">
            <Button
              className="standard-button tertiary-button"
              onClick={() => setWarningModalVisible(false)}
            >
              No
            </Button>
            <Button
              className="standard-button primary-orange-button"
              type="primary"
              onClick={() => setWarningModalVisible(false)}
            >
              Yes
            </Button>
          </div>
        </div>
      </Modal>

      {/* for disconnect shopify */}
      <Modal
        centered
        visible={showDisconnectShopifyModal}
        footer={null}
        className="mapped-warning-modal-container"
        onCancel={() => setShowDisconnectShopifyModal(false)}
      >
        <div className="mapped-warning-modal">
          <img src={glowingBulbIcon} alt="" />
          <p className="warning-modal-dark-text">
            Sure you want to disconnect Blustream app from shopify?
          </p>
          <p className="warning-modal-light-text">
            All products from your Shopify store linked with Blustream will be
            removed!
          </p>
          <div className="mapped-warning-modal-container">
            <Button
              className="standard-button tertiary-button"
              onClick={() => setShowDisconnectShopifyModal(false)}
            >
              No
            </Button>
            <Button
              loading={disonnectLoading}
              className="standard-button primary-orange-button"
              type="primary"
              onClick={() => removeShopifyProfile()}
            >
              Yes
            </Button>
          </div>
        </div>
      </Modal>

      {/* shopify native concent modal */}
      <Modal
        centered
        visible={showShopifyNativeConcentModal}
        maskClosable={false}
        width="70%"
        footer={
          <div>
            <Button
              className={`slider-button ${
                currentNativeSlideNumber === 0 ? "current-slide" : ""
              }`}
              onClick={() => {
                setCurrentNativeSlideNumber(0);
                slider1.current.goTo(0);
              }}
            >
              1
            </Button>
            <Button
              className={`slider-button ${
                currentNativeSlideNumber === 1 ? "current-slide" : ""
              }`}
              onClick={() => {
                setCurrentNativeSlideNumber(1);
                slider1.current.goTo(1);
              }}
            >
              2
            </Button>
            <Button
              className={`slider-button ${
                currentNativeSlideNumber === 2 ? "current-slide" : ""
              }`}
              onClick={() => {
                setCurrentNativeSlideNumber(2);
                slider1.current.goTo(2);
              }}
            >
              3
            </Button>
            <Button
              className={`slider-button ${
                currentNativeSlideNumber === 3 ? "current-slide" : ""
              }`}
              onClick={() => {
                setCurrentNativeSlideNumber(3);
                slider1.current.goTo(3);
              }}
            >
              4
            </Button>
            <Button
              className={`slider-button ${
                currentNativeSlideNumber === 4 ? "current-slide" : ""
              }`}
              onClick={() => {
                setCurrentNativeSlideNumber(4);
                slider1.current.goTo(4);
              }}
            >
              5
            </Button>
            <Button
              className={`slider-button ${
                currentNativeSlideNumber === 5 ? "current-slide" : ""
              }`}
              onClick={() => {
                setCurrentNativeSlideNumber(5);
                slider1.current.goTo(5);
              }}
            >
              6
            </Button>
            <Button
              className={`slider-button ${
                currentNativeSlideNumber === 6 ? "current-slide" : ""
              }`}
              onClick={() => {
                setCurrentNativeSlideNumber(6);
                slider1.current.goTo(6);
              }}
            >
              7
            </Button>
            <Button
              className={`slider-button ${
                currentNativeSlideNumber === 7 ? "current-slide" : ""
              }`}
              onClick={() => {
                setCurrentNativeSlideNumber(7);
                slider1.current.goTo(7);
              }}
            >
              8
            </Button>
          </div>
        }
        className="shopify-native-concent-modal"
        onCancel={() => {
          setShowShopifyNativeConcentModal(false);
          setCurrentNativeSlideNumber(0);
          slider1.current.goTo(0);
        }}
      >
        <Carousel dots={false} ref={slider1} adaptiveHeight={true}>
          <div>
            <img src={shopifyNativeConcent1} alt="shopifyNativeConcentslide1" />
          </div>
          <div>
            <img src={shopifyNativeConcent2} alt="shopifyNativeConcentslide2" />
          </div>
          <div>
            <img src={shopifyNativeConcent3} alt="shopifyNativeConcentslide3" />
          </div>
          <div>
            <img src={shopifyNativeConcent4} alt="shopifyNativeConcentslide4" />
          </div>
          <div>
            <img src={shopifyNativeConcent5} alt="shopifyNativeConcentslide5" />
          </div>
          <div>
            <img src={shopifyNativeConcent6} alt="shopifyNativeConcentslide6" />
          </div>
          <div>
            <img src={shopifyNativeConcent7} alt="shopifyNativeConcentslide7" />
          </div>
          <div>
            <img src={shopifyNativeConcent8} alt="shopifyNativeConcentslide8" />
          </div>
        </Carousel>
      </Modal>

      {/* shopify active extension modal */}
      <Modal
        centered
        visible={showShopifyActivateExtensionModal}
        maskClosable={false}
        width="70%"
        footer={
          <div>
            <Button
              className={`slider-button ${
                currentNativeSlideNumber === 0 ? "current-slide" : ""
              }`}
              onClick={() => {
                setCurrentNativeSlideNumber(0);
                slider2.current.goTo(0);
              }}
            >
              1
            </Button>
            <Button
              className={`slider-button ${
                currentNativeSlideNumber === 1 ? "current-slide" : ""
              }`}
              onClick={() => {
                setCurrentNativeSlideNumber(1);
                slider2.current.goTo(1);
              }}
            >
              2
            </Button>
            <Button
              className={`slider-button ${
                currentNativeSlideNumber === 2 ? "current-slide" : ""
              }`}
              onClick={() => {
                setCurrentNativeSlideNumber(2);
                slider2.current.goTo(2);
              }}
            >
              3
            </Button>
            <Button
              className={`slider-button ${
                currentNativeSlideNumber === 3 ? "current-slide" : ""
              }`}
              onClick={() => {
                setCurrentNativeSlideNumber(3);
                slider2.current.goTo(3);
              }}
            >
              4
            </Button>
            <Button
              className={`slider-button ${
                currentNativeSlideNumber === 4 ? "current-slide" : ""
              }`}
              onClick={() => {
                setCurrentNativeSlideNumber(4);
                slider2.current.goTo(4);
              }}
            >
              5
            </Button>
            <Button
              className={`slider-button ${
                currentNativeSlideNumber === 5 ? "current-slide" : ""
              }`}
              onClick={() => {
                setCurrentNativeSlideNumber(5);
                slider2.current.goTo(5);
              }}
            >
              6
            </Button>
            <Button
              className={`slider-button ${
                currentNativeSlideNumber === 6 ? "current-slide" : ""
              }`}
              onClick={() => {
                setCurrentNativeSlideNumber(6);
                slider2.current.goTo(6);
              }}
            >
              7
            </Button>
          </div>
        }
        className="shopify-activate-extension-modal"
        onCancel={() => {
          setShowShopifyActivateExtensionModal(false);
          setCurrentNativeSlideNumber(0);
          slider2.current.goTo(0);
        }}
      >
        <Carousel dots={false} ref={slider2} adaptiveHeight={true}>
          <div>
            <img src={shopifyExtension1} alt="shopifyExtensionslide1" />
          </div>
          <div>
            <img src={shopifyExtension2} alt="shopifyExtensionslide2" />
          </div>
          <div>
            <img src={shopifyExtension3} alt="shopifyExtensionslide3" />
          </div>
          <div>
            <img src={shopifyExtension4} alt="shopifyExtensionslide4" />
          </div>
          <div>
            <img src={shopifyExtension5} alt="shopifyExtensionslide5" />
          </div>
          <div>
            <img src={shopifyExtension6} alt="shopifyExtensionslide6" />
          </div>
          <div>
            <img src={shopifyExtension7} alt="shopifyExtensionslide7" />
          </div>
        </Carousel>
      </Modal>

      {/* shopify active extension modal */}
      <Modal
        centered
        visible={showConcentTextModal}
        maskClosable={false}
        width="70%"
        footer={null}
        className="shopify-concent-text-modal"
        onCancel={() => {
          setShowConcentTextModal(false);
        }}
      >
        <div>
          <img src={shopifyConcentPage} alt="shopifyNativeConcentslide7" />
        </div>
      </Modal>

      {/* update tritech key warning modal */}
      <Modal
        centered
        visible={keyUpdateWarningModal}
        footer={null}
        className="mapped-warning-modal-container delete-attribute-modal"
        onCancel={() => setKeyUpdateWarningModal(false)}
        closable={false}
      >
        <div className="mapped-warning-modal">
          <img src={glowingBulbIcon} alt="" />
          <p className="warning-modal-dark-text">
            Are you sure you want to generate new secret key?
          </p>
          <p className="warning-modal-light-text">
            You will need to update new secret key in Tri-Tech AIMS portal once
            you generate new key.
          </p>
          <div className="mapped-warning-modal-container">
            <Button
              className="standard-button tertiary-button"
              onClick={() => setKeyUpdateWarningModal(false)}
            >
              No
            </Button>
            <Button
              className="standard-button primary-orange-button"
              type="primary"
              onClick={async () => {
                await updateTriTechProfile();
                setKeyUpdateWarningModal(false);
              }}
              loading={updateTriTechLoading}
            >
              Yes
            </Button>
          </div>
        </div>
      </Modal>

      {/* tritech events update modal */}
      <Modal
        centered
        visible={showTriTechEventConfigureModal}
        footer={null}
        className="tritech-mapping-modal"
        title={
          <div className="tritech-mapping-modal-header-wrapper">
            <div className="blustream-product-details">
              <span className="product-name">{selectedEvent?.action}</span>
            </div>
            <div className="blustream-product-details">
              <Button
                className="standard-button primary-light-blue-button"
                type="secondary"
                loading={updateTriTechEventLoader}
                onClick={() => {
                  tritechEventConfigureForm?.validateFields().then(async () => {
                    setUpdateTriTechEventLoader(true);
                    await updateTriTechEvent();
                    setUpdateTriTechEventLoader(false);
                    setShowTriTechEventConfigureModal(false);
                    tritechEventConfigureForm?.resetFields();
                  });
                }}
              >
                Save
              </Button>
            </div>
          </div>
        }
        onCancel={() => {
          setShowTriTechEventConfigureModal(false);
          tritechEventConfigureForm?.resetFields();
        }}
      >
        <div className="tritech-event-configure-modal-content-wrapper">
          <div className="field-title">Transaction Status</div>
          <Form
            layout="vertical"
            form={tritechEventConfigureForm}
            requiredMark={false}
            className="tritech-event-configuration-form"
          >
            {transactionStatusArray?.map((status, index) => {
              return (
                <div className="transaction-status-container">
                  <Form.Item
                    name={`status${index}`}
                    rules={[
                      {
                        required: true,
                        message: `Please Enter value!`,
                      },
                    ]}
                  >
                    <Input
                      className="standard-input"
                      value={status}
                      onChange={(event) => {
                        setTransactionStatusArray((prev) => {
                          const newArray = [...prev];
                          newArray[index] = event.target.value?.trimStart();
                          const formValues = {};
                          newArray?.forEach((value, index) => {
                            formValues[`status${index}`] = value;
                          });
                          tritechEventConfigureForm?.setFieldsValue(formValues);
                          return newArray;
                        });
                      }}
                    />
                  </Form.Item>
                  <MinusCircleOutlined
                    className="remove-field-button"
                    onClick={() => {
                      setTransactionStatusArray((prev) => {
                        tritechEventConfigureForm?.resetFields();
                        const newArray = [...prev];
                        newArray.splice(index, 1);
                        const formValues = {};
                        newArray?.forEach((value, index) => {
                          formValues[`status${index}`] = value;
                        });
                        tritechEventConfigureForm?.setFieldsValue(formValues);
                        return newArray;
                      });
                    }}
                  />
                </div>
              );
            })}
          </Form>
          <div className="important-text">
            <span style={{ fontWeight: "600" }}>*Important</span> : Transaction
            Status is for (some text goes here)
          </div>
          <Button
            className="standard-button secondary-orange-button add-status-button"
            onClick={() => {
              setTransactionStatusArray((prev) => {
                return [...prev, ""];
              });
            }}
          >
            Add More Status
          </Button>
        </div>
      </Modal>
    </div>
  );
}
