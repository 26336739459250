/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getPlan = /* GraphQL */ `
  query GetPlan($id: ID!) {
    getPlan(id: $id) {
      id
      name
      description
      limits
      stripePriceID
      priceValues
      listed
      order
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listPlans = /* GraphQL */ `
  query ListPlans(
    $filter: ModelPlanFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPlans(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        limits
        stripePriceID
        priceValues
        listed
        order
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDashboard = /* GraphQL */ `
  query GetDashboard($id: ID!) {
    getDashboard(id: $id) {
      id
      name
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listDashboards = /* GraphQL */ `
  query ListDashboards(
    $filter: ModelDashboardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDashboards(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAdminPlan = /* GraphQL */ `
  query GetAdminPlan($id: ID!) {
    getAdminPlan(id: $id) {
      id
      name
      description
      limits
      stripePriceID
      priceValues
      listed
      order
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAdminPlans = /* GraphQL */ `
  query ListAdminPlans(
    $filter: ModelAdminPlanFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAdminPlans(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        limits
        stripePriceID
        priceValues
        listed
        order
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchCustomerAccounts = /* GraphQL */ `
  query SearchCustomerAccounts(
    $filter: SearchableCustomerAccountFilterInput
    $sort: SearchableCustomerAccountSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchCustomerAccounts(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        company
        company_loweredcased
        url
        disabled
        users
        shopifyPlanID
        limits
        metadata
        dashboardID
        source
        createdAt
        manualBilling
        updatedAt
        owner
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripeSubscriptionDetails
        stripePriceID
        planID
        __typename
      }
      nextToken
      total
      __typename
    }
  }
`;
export const getCustomerAccount = /* GraphQL */ `
  query GetCustomerAccount($id: ID!) {
    getCustomerAccount(id: $id) {
      id
      company
      company_loweredcased
      url
      disabled
      users
      shopifyPlanID
      limits
      metadata
      dashboardID
      source
      createdAt
      manualBilling
      updatedAt
      plan {
        id
        name
        description
        limits
        stripePriceID
        priceValues
        listed
        order
        createdAt
        updatedAt
        __typename
      }
      owner
      accountStatus
      stripeCustomerID
      stripeSubscriptionID
      stripeSubscriptionDetails
      stripePriceID
      planID
      requests {
        nextToken
        __typename
      }
      tags {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const listCustomerAccounts = /* GraphQL */ `
  query ListCustomerAccounts(
    $filter: ModelCustomerAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomerAccounts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        company
        company_loweredcased
        url
        disabled
        users
        shopifyPlanID
        limits
        metadata
        dashboardID
        source
        createdAt
        manualBilling
        updatedAt
        owner
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripeSubscriptionDetails
        stripePriceID
        planID
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCustomerAccountRequest = /* GraphQL */ `
  query GetCustomerAccountRequest($id: ID!) {
    getCustomerAccountRequest(id: $id) {
      id
      account
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCustomerAccountRequests = /* GraphQL */ `
  query ListCustomerAccountRequests(
    $filter: ModelCustomerAccountRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomerAccountRequests(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        account
        owner
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getContact = /* GraphQL */ `
  query GetContact($id: ID!) {
    getContact(id: $id) {
      id
      account
      firstname
      lastname
      email
      phone
      attributes
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listContacts = /* GraphQL */ `
  query ListContacts(
    $filter: ModelContactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContacts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        account
        firstname
        lastname
        email
        phone
        attributes
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getInviteHistory = /* GraphQL */ `
  query GetInviteHistory($email: String!) {
    getInviteHistory(email: $email) {
      email
      account
      invitedby
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listInviteHistories = /* GraphQL */ `
  query ListInviteHistories(
    $email: String
    $filter: ModelInviteHistoryFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listInviteHistories(
      email: $email
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        email
        account
        invitedby
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCompanyInviteHistory = /* GraphQL */ `
  query GetCompanyInviteHistory($id: ID!) {
    getCompanyInviteHistory(id: $id) {
      id
      email
      account
      invitedby
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCompanyInviteHistories = /* GraphQL */ `
  query ListCompanyInviteHistories(
    $filter: ModelCompanyInviteHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanyInviteHistories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        account
        invitedby
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getApiKey = /* GraphQL */ `
  query GetApiKey($id: ID!) {
    getApiKey(id: $id) {
      id
      account
      key
      keyid
      disabled
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listApiKeys = /* GraphQL */ `
  query ListApiKeys(
    $filter: ModelApiKeyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listApiKeys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        account
        key
        keyid
        disabled
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTermsAndConditions = /* GraphQL */ `
  query GetTermsAndConditions($id: ID!) {
    getTermsAndConditions(id: $id) {
      id
      url
      metadata
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listTermsAndConditions = /* GraphQL */ `
  query ListTermsAndConditions(
    $filter: ModelTermsAndConditionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTermsAndConditions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        url
        metadata
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPrivacyPolicy = /* GraphQL */ `
  query GetPrivacyPolicy($id: ID!) {
    getPrivacyPolicy(id: $id) {
      id
      url
      metadata
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listPrivacyPolicies = /* GraphQL */ `
  query ListPrivacyPolicies(
    $filter: ModelPrivacyPolicyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPrivacyPolicies(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        url
        metadata
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTag = /* GraphQL */ `
  query GetTag($id: ID!) {
    getTag(id: $id) {
      id
      name
      metadata
      createdAt
      updatedAt
      customerAccounts {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const listTags = /* GraphQL */ `
  query ListTags(
    $filter: ModelTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        metadata
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCustomerAccountTag = /* GraphQL */ `
  query GetCustomerAccountTag($id: ID!) {
    getCustomerAccountTag(id: $id) {
      id
      createdAt
      updatedAt
      customerAccount {
        id
        company
        company_loweredcased
        url
        disabled
        users
        shopifyPlanID
        limits
        metadata
        dashboardID
        source
        createdAt
        manualBilling
        updatedAt
        owner
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripeSubscriptionDetails
        stripePriceID
        planID
        __typename
      }
      tag {
        id
        name
        metadata
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const listCustomerAccountTags = /* GraphQL */ `
  query ListCustomerAccountTags(
    $filter: ModelCustomerAccountTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomerAccountTags(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMicrosite = /* GraphQL */ `
  query GetMicrosite($id: ID!) {
    getMicrosite(id: $id) {
      id
      account
      disabled
      templateName
      micrositeFormElements
      micrositeStyle
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listMicrosites = /* GraphQL */ `
  query ListMicrosites(
    $filter: ModelMicrositeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMicrosites(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        account
        disabled
        templateName
        micrositeFormElements
        micrositeStyle
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTriTechProfile = /* GraphQL */ `
  query GetTriTechProfile($id: ID!) {
    getTriTechProfile(id: $id) {
      id
      account
      enabled
      product_data
      secret_key
      createdAt
      updatedAt
      tritech_contract_action_mapper {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const listTriTechProfiles = /* GraphQL */ `
  query ListTriTechProfiles(
    $filter: ModelTriTechProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTriTechProfiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        account
        enabled
        product_data
        secret_key
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const TriTechProfileByAccountId = /* GraphQL */ `
  query TriTechProfileByAccountId(
    $account: ID
    $sortDirection: ModelSortDirection
    $filter: ModelTriTechProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    TriTechProfileByAccountId(
      account: $account
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        account
        enabled
        product_data
        secret_key
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTriTechContractActionMapper = /* GraphQL */ `
  query GetTriTechContractActionMapper($id: ID!) {
    getTriTechContractActionMapper(id: $id) {
      id
      triTechProfileId
      payloadDescription
      payloadTransactionStatus
      action
      createdAt
      updatedAt
      triTechProfile {
        id
        account
        enabled
        product_data
        secret_key
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const listTriTechContractActionMappers = /* GraphQL */ `
  query ListTriTechContractActionMappers(
    $filter: ModelTriTechContractActionMapperFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTriTechContractActionMappers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        triTechProfileId
        payloadDescription
        payloadTransactionStatus
        action
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTriTechProductMapping = /* GraphQL */ `
  query GetTriTechProductMapping($id: ID!) {
    getTriTechProductMapping(id: $id) {
      id
      account
      blustream_product_id
      tritech_product_categories
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listTriTechProductMappings = /* GraphQL */ `
  query ListTriTechProductMappings(
    $filter: ModelTriTechProductMappingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTriTechProductMappings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        account
        blustream_product_id
        tritech_product_categories
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const TriTechProductMappingByAccountId = /* GraphQL */ `
  query TriTechProductMappingByAccountId(
    $account: ID
    $sortDirection: ModelSortDirection
    $filter: ModelTriTechProductMappingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    TriTechProductMappingByAccountId(
      account: $account
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        account
        blustream_product_id
        tritech_product_categories
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getShopifyProfile = /* GraphQL */ `
  query GetShopifyProfile($id: ID!) {
    getShopifyProfile(id: $id) {
      id
      account
      store_name
      access_token
      refresh_token
      enabled
      concent
      concentTitle
      showSteper
      isProfileLock
      showWelcomePopUp
      shopifyStoreDomain
      shopifyStoreId
      plan_details
      add_contacts_without_marketing_consent
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listShopifyProfiles = /* GraphQL */ `
  query ListShopifyProfiles(
    $filter: ModelShopifyProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listShopifyProfiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        account
        store_name
        access_token
        refresh_token
        enabled
        concent
        concentTitle
        showSteper
        isProfileLock
        showWelcomePopUp
        shopifyStoreDomain
        shopifyStoreId
        plan_details
        add_contacts_without_marketing_consent
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const ShopifyProfileByAccountId = /* GraphQL */ `
  query ShopifyProfileByAccountId(
    $account: ID
    $sortDirection: ModelSortDirection
    $filter: ModelShopifyProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ShopifyProfileByAccountId(
      account: $account
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        account
        store_name
        access_token
        refresh_token
        enabled
        concent
        concentTitle
        showSteper
        isProfileLock
        showWelcomePopUp
        shopifyStoreDomain
        shopifyStoreId
        plan_details
        add_contacts_without_marketing_consent
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getShopifyProductMapping = /* GraphQL */ `
  query GetShopifyProductMapping($id: ID!) {
    getShopifyProductMapping(id: $id) {
      id
      account
      blustream_product_id
      shopify_product_ids
      shopify_product_variants
      shopify_product_details
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listShopifyProductMappings = /* GraphQL */ `
  query ListShopifyProductMappings(
    $filter: ModelShopifyProductMappingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listShopifyProductMappings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        account
        blustream_product_id
        shopify_product_ids
        shopify_product_variants
        shopify_product_details
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const ShopifyProductMappingByAccountId = /* GraphQL */ `
  query ShopifyProductMappingByAccountId(
    $account: ID
    $sortDirection: ModelSortDirection
    $filter: ModelShopifyProductMappingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ShopifyProductMappingByAccountId(
      account: $account
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        account
        blustream_product_id
        shopify_product_ids
        shopify_product_variants
        shopify_product_details
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDelayStartAccounts = /* GraphQL */ `
  query GetDelayStartAccounts($id: ID!) {
    getDelayStartAccounts(id: $id) {
      id
      account
      expirationUnixTime
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listDelayStartAccounts = /* GraphQL */ `
  query ListDelayStartAccounts(
    $filter: ModelDelayStartAccountsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDelayStartAccounts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        account
        expirationUnixTime
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAfterShipProfile = /* GraphQL */ `
  query GetAfterShipProfile($id: ID!) {
    getAfterShipProfile(id: $id) {
      id
      account
      webhook_secret
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAfterShipProfiles = /* GraphQL */ `
  query ListAfterShipProfiles(
    $filter: ModelAfterShipProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAfterShipProfiles(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        account
        webhook_secret
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
